<template>
  <div class="class-ticket-record">
    <PageTitle
      title="點數卡紀錄"
      btn="匯出新增紀錄"
      btn2="匯出使用紀錄"
      btnPlain
      @btnClick=";(exportType = 'add'), (dialog.export = true)"
      @btn2Click=";(exportType = 'use'), (dialog.export = true)"
    />

    <FiltersContainer>
      <MemberSearch :model.sync="search.member" @change="refresh" @clear="refresh(true)" />
      <BaseElInput
        v-model="search.code"
        style="max-width: 400px"
        clearable
        placeholder="搜尋點數卡編號"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>

      <BaseElInput
        v-model="search.name"
        style="max-width: 400px"
        clearable
        placeholder="搜尋點數卡名稱"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
      <BaseElSelect
        v-model="search.status"
        style="max-width: 400px"
        clearable
        placeholder="狀態"
        @change="refresh(true)"
      >
        <BaseElSelectOption
          v-for="item in pointCardStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
    </FiltersContainer>

    <section>
      <BaseTable
        v-loading="loading.table"
        :data="displayData"
        empty-text="暫無數據"
        testName="pointCardRecord"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="持有會員" align="center" fixed="left">
          <template slot-scope="scope">
            <div class="underline cursor-pointer" @click="goToProfile(scope.row)">
              {{ get(scope.row, 'Member.UserInfo.name') }}
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="code" label="點數卡編號" align="center" />
        <BaseElTableColumn prop="name" label="點數卡名稱" align="center">
          <template slot-scope="scope">
            <LinkItem :to="{ name: 'PointCardSetting', query: { name: scope.row.name } }">{{
              scope.row.name
            }}</LinkItem>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="status" label="狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="selectStatus(scope.row.status, 'tagType')">
              {{ selectStatus(scope.row.status, 'label') }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="amount" label="餘額/總次數" align="center" />
        <BaseElTableColumn prop="price" label="價格" align="center" />
        <BaseElTableColumn prop="createdAt" label="建立時間" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="exp" label="有效期限" align="center" />
        <BaseElTableColumn prop="origin" label="來源" align="center">
          <template slot-scope="scope">
            {{ selectOrigin(scope.row.origin, 'label') }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              v-if="scope.row.Member"
              :testName="`editPointCard_${scope.row.code}`"
              editBtn="詳情"
              deleteBtn="作廢"
              :hideDelete="scope.row.status !== 'available'"
              @edit="findPointCard(scope.row)"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
            <div v-else>-</div>
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="作廢後將無法復原，確定要作廢？"
      width="40%"
      btnString="作廢"
      @close="deleteDialog = false"
      @delete="voidPointCard(), (deleteDialog = false)"
    />
    <PointCardRecordDetail
      :show="showDrawer"
      :selectPointCard="selectRow"
      @close="closeDrawer"
      @cancel="deleteDialog = true"
      @refresh="refresh"
    />
    <ExportOptionsDialog
      v-if="dialog.export"
      :useExportTask="(ExportType = 'add' ? usePointCardRecord : usePointCardRecordUse)"
      @close="dialog.export = false"
      @export="prepareExport"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed, onActivated, onMounted } from 'vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import PointCardRecordDetail from '@/views/PointCard/components/PointCardRecordDetail.vue'
import LinkItem from '@/components/LinkItem.vue'
import {
  GetPointCardRecord,
  GetPointCardRecordCount,
  VoidPointCardRecord,
  FindPointCardRecord,
} from '@/api/pointCard'
import {
  CreatePointCardRecordSheetExportTask,
  CreatePointCardRecordUseSheetExportTask,
} from '@/api/exportTask'
import { pointCardStatusConfig, pointCardOriginConfig } from '@/config/pointCard'
import notifyMessage from '@/config/notifyMessage'
import { get, map } from 'lodash'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'
import { useExportCenter } from '@/use/useExportCenter'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'PointCardRecord',
  components: {
    MemberSearch,
    EmptyBlock,
    ExportOptionsDialog,
    DeleteDialog,
    PointCardRecordDetail,
    LinkItem,
  },
  setup(props) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const router = useRouter()
    const { messageOptions } = useExportCenter()
    const exportType = ref('add')
    const search = reactive({
      code: '',
      name: '',
      status: null,
      member: null,
    })
    const dialog = reactive({
      export: false,
    })
    const showDrawer = ref(false)
    const selectRow = ref(null)
    const deleteDialog = ref(false)
    const usePointCardRecordUse = computed(() =>
      checkAction('admin.pointCardRecord.createSheetExportTaskUsageLog'),
    )
    const usePointCardRecord = computed(() =>
      checkAction('admin.pointCardRecord.createSheetExportTask'),
    )
    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        const status = get(item, 'status')
        const remainAmountName = selectStatus(status, 'amount')
        const remainAmount = item[remainAmountName]
        const amount =
          item.totalAmount === null
            ? '無上限'
            : `${selectStatus(status, 'amountLabel')} ${remainAmount}/${item.totalAmount}`
        const exp = item.expiredAt === null ? '無期限' : dateFormat(item.expiredAt)
        return {
          ...item,
          amount,
          exp,
        }
      })
    })
    const selectStatus = (status, attr) => {
      return get(pointCardStatusConfig[status], attr)
    }
    const selectOrigin = (origin, attr) => {
      return get(pointCardOriginConfig[origin], attr)
    }
    const voidPointCard = async () => {
      const [res, err] = await VoidPointCardRecord({
        shopId: shopId.value,
        pointCardRecordId: get(selectRow, 'value.id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.voidSuccess)
      await refresh()
    }
    const prepareExport = async ({ all, range }) => {
      dialog.export = false
      const payload = {
        shopId: shopId.value,
        // MemberId: get(search.member, 'id') || undefined,
        // code: search.code || undefined,
        // ClassTicketId: search.classTicket || undefined,
        // createdAtStart: get(range, '[0]') ? dayjs(get(range, '[0]')).toDate() : undefined,
        // createdAtEnd: get(range, '[0]') ? dayjs(get(range, '[1]')).toDate() : undefined,
      }

      if (exportType.value === 'use' && usePointCardRecordUse.value) {
        await createSheetExportTask(CreatePointCardRecordUseSheetExportTask, payload)
      } else if (exportType.value === 'add' && usePointCardRecord.value) {
        await createSheetExportTask(CreatePointCardRecordSheetExportTask, payload)
      }
    }
    const createSheetExportTask = async (apiFunc, payload) => {
      const [, err] = await apiFunc(payload)
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message(messageOptions.value)
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        code: search.code || undefined,
        MemberId: search.member ? search.member.id : undefined,
        name: search.name || undefined,
        status: search.status || undefined,
      }
      await Promise.allSettled([
        fetchData(GetPointCardRecord, payload),
        fetchDataCount(GetPointCardRecordCount, payload),
      ])
    }
    const refresh = async (resetPage) => {
      if (resetPage) tableOptions.page = 1
      loading.table = true
      await getTableData()
      if (selectRow.value) await findPointCardRecord()
      loading.table = false
    }
    const closeDrawer = () => {
      showDrawer.value = false
      selectRow.value = null
    }
    const findPointCard = async (row) => {
      selectRow.value = row
      await findPointCardRecord()
      showDrawer.value = true
    }
    const findPointCardRecord = async () => {
      const [res, err] = await FindPointCardRecord({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      selectRow.value = res
    }
    const goToProfile = (row) => {
      router.push(`/members/member-profile/${row.MemberId}/pointCard`)
    }
    onMounted(async () => {
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })
    return {
      search,
      prepareExport,
      dialog,
      get,
      exportType,
      usePointCardRecordUse,
      usePointCardRecord,
      pointCardStatusConfig,
      selectStatus,
      voidPointCard,
      refresh,
      loading,
      tableData,
      tableDataCount,
      tableOptions,
      goToProfile,
      closeDrawer,
      deleteDialog,
      selectRow,
      showDrawer,
      dateFormat,
      findPointCard,
      displayData,
      selectOrigin,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  @apply w-full max-w-[400px];
}
</style>
